import React, { useState, Fragment } from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import Popover from 'components/uiLibrary/Popover';
import LinkButton from 'components/uiLibrary/LinkButton';

import usePageContext from 'utils/hooks/usePageContext';
import { createDate } from 'utils/date';
import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import { useTranslation } from 'src/i18n';
import { TP, DATE_FORMATS, ENTITY_TYPE, PERFORMANCE_DATE_MODE_TYPES } from 'constants/index';

import classes from './DatesList.module.scss';

const DatesList = ({
  className,
  dates,
  month,
  year,
  isCancelled,
  showIfCancelled = true,
  shouldShowYear = true,
  showDummyYear = false, // this is to show dates align with venues within same year not city and country
  openInNewTab = false,
  entity,
  isProSlug,
  entityType = ENTITY_TYPE.PRODUCTION,
  trackingData,
  styles = {},
}) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const [anchorEl, setAnchorEl] = useState(null);
  const { navigate } = usePageContext();

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classnames(classes.root, className)}>
      {shouldShowYear && <Typography weight="medium">{year}</Typography>}
      {showDummyYear && (
        <Typography weight="medium" className={classes.dummyYear}>
          {year}
        </Typography>
      )}
      <div className={classnames(classes.dates, { [styles.dates]: !!styles.dates })}>
        <Typography className={classnames(classes.dates__month, { [styles.month]: !!styles.month })}>
          {month}
        </Typography>
        {dates?.map((date, index) => {
          if (!date?.startDate) {
            return null;
          }

          const dateObj = createDate(date?.startDate, DATE_FORMATS.FULL_DATE);
          const linkProps = navigate.getLinkProps({
            entityType,
            entity,
            path: dateObj.locale('en').format(DATE_FORMATS.URL_FULL_DATE),
          });

          return (
            <Fragment key={`${dateObj?.format?.(DATE_FORMATS.FULL_DATE)}-${index}`}>
              <div
                className={classnames({
                  [classes.cancelled]: showIfCancelled && (date?.isCancelled || isCancelled),
                })}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                {...((date?.isCancelled || isCancelled) &&
                  showIfCancelled && {
                    onMouseEnter: handlePopoverOpen,
                    onMouseLeave: handlePopoverClose,
                  })}
              >
                <LinkButton
                  variant="text"
                  styles={{ root: classnames(classes.dates__item, { [styles.dateItem]: !!styles.dateItem }) }}
                  trackingData={{
                    ...trackingData,
                    subComponent: trackingData?.subComponent || SUB_COMPONENTS.DATE,
                    entityId: entity?.id,
                    entityName: entity?.name,
                    entityType: ENTITY_TYPE.PRODUCTION,
                    meta: {
                      ...trackingData?.meta,
                      performanceId: date?.id,
                      performanceDate: date?.startDate,
                    },
                  }}
                  {...(!isProSlug && { isLink: true, ...linkProps })}
                  {...(isProSlug && { disableUnderline: true })}
                  {...(openInNewTab && { target: '_blank' })}
                >
                  {' '}
                  <time className={classnames(classes.dateTime)} dateTime={dateObj?.format(DATE_FORMATS.FULL_DATE)}>
                    {date.id !== -1 ? dateObj?.format(DATE_FORMATS.DATE) : date?.startTime}
                  </time>
                  {date?.isMatinee && (
                    <Typography size="12" color="secondary" italic className={classes.dates__itemSubText}>
                      m
                    </Typography>
                  )}
                  {date?.isTour && (
                    <Typography size="12" color="secondary" italic className={classes.dates__itemSubText}>
                      t
                    </Typography>
                  )}
                  {date?.mode === PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM && (
                    <Typography size="12" color="secondary" italic className={classes.dates__itemSubText}>
                      ls
                    </Typography>
                  )}
                  {index !== dates.length - 1 && <Typography>{', '}</Typography>}
                </LinkButton>
              </div>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                styles={{ root: classes.dateCancelledPopover }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                {t(`${TP}.PERFORMANCE_CANCELLED`)}
              </Popover>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default DatesList;
